<template>
  <div
    class="top"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 上 -->
    <div class="protop">
      <div class="play">
        <span>关键字搜索</span>
        <el-select
          v-model="valueProduct"
          clearable
          placeholder="请选择"
          @change="typeoneProduct"
        >
          <el-option
            v-for="item in optionsProduct"
            :key="item.value"
            :label="item.type"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="antistop">
        <el-input
          placeholder="请选择关键字类型"
          v-model="input"
          clearable
          class="guanjian"
        ></el-input>
        <div class="buttons">
          <img
            src="../../assets/images/query.png"
            alt
            @click="searchProduct"
            class="btn-img"
          />
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="销售分组名称">
          <template slot-scope="scope">
            <div @click="rowClick(scope.row)">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="企业名称" width="180">
          <template slot-scope="scope">
            <div @click="rowClick(scope.row)">{{ scope.row.companyName }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="linkman" label="联系人">
          <template slot-scope="scope">
            <div @click="rowClick(scope.row)">{{ scope.row.linkman }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="联系电话">
          <template slot-scope="scope">
            <div @click="rowClick(scope.row)">{{ scope.row.phone }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址" width="200">
          <template slot-scope="scope">
            <div @click="rowClick(scope.row)">{{ scope.row.address }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="specialCn" label="特殊分组">
          <template slot-scope="scope">
            <div @click="rowClick(scope.row)">{{ scope.row.specialCn }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="equipmentNum" label="设备数量">
        </el-table-column>
        <el-table-column label="参数配置">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >配置</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 下边 -->
    <div class="bottom">
      <div class="bottom-left">
        <el-button round class="top-btn" @click="addList"
          >增加销售分组</el-button
        >
      </div>
      <div class="bottom-right">
        <div class="bottom-fen">
          <!-- 分页 -->
          <div class="pages">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :current-page="pageCount"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
        <div class="buttons">
          <el-button>数据分析</el-button>
          <el-button @click="dataExport">数据导出</el-button>
        </div>
      </div>
    </div>
    <!-- 增加按钮的弹框 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <el-form :model="formInline" class="demo-form-inline">
        <el-form-item label="销售分组名称">
          <el-input
            v-model="formInline.name"
            placeholder="输入销售分组名称"
            class="tan-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业名称">
          <el-input
            v-model="formInline.companyName"
            placeholder="输入企业名称"
            class="tan-inputOne"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input
            v-model="formInline.linkman"
            placeholder="输入联系人"
            class="tan-inputTwo"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            v-model="formInline.phone"
            placeholder="输入联系电话"
            class="tan-inputOne"
          ></el-input>
        </el-form-item>
        <el-form-item label="特殊分组">
          <el-select
            v-model="formInline.special"
            class="tan-inputOne"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in versions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构">
          <el-select
            v-model="formInline.orgId"
            class="tan-inputOne"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in orgIds"
              :key="item.id"
              :label="item.orgName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址">
          <el-input
            v-model="formInline.address"
            placeholder="输入联系地址"
            class="tan-inputThree"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" class="tan-que"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
    <!-- 修改按钮的弹框 -->
    <el-dialog title="" :visible.sync="dialogVisibleC" width="40%">
      <el-form :model="formInlineOne" class="demo-form-inline">
        <el-form-item label="销售分组名称">
          <el-input
            v-model="formInlineOne.name"
            placeholder="输入销售分组名称"
            class="tan-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业名称">
          <el-input
            v-model="formInlineOne.companyName"
            placeholder="输入企业名称"
            class="tan-inputOne"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input
            v-model="formInlineOne.linkman"
            placeholder="输入联系人"
            class="tan-inputTwo"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            v-model="formInlineOne.phone"
            placeholder="输入联系电话"
            class="tan-inputOne"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input
            v-model="formInlineOne.address"
            placeholder="输入地址"
            class="tan-inputFour"
          ></el-input>
        </el-form-item>
        <el-form-item label="特殊分组">
          <el-select
            v-model="formInlineOne.special"
            class="tan-inputOne"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in versions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构">
          <el-select
            v-model="formInlineOne.orgId"
            class="tan-inputOne"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in orgIds"
              :key="item.id"
              :label="item.orgName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmitOne" class="tan-que"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="dialogVisibles"
      :before-close="handleCloses"
    >
      <configure ref="configures" @codeOkTwo="codeOkTwo"></configure>
    </el-dialog>
  </div>
</template>

<script>
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
import configure from "./configure.vue";
export default {
  components: { configure },
  data() {
    return {
      clientHeight: document.body.clientHeight,
      groupName: "",
      versions: [],
      orgIds: [],
      dialogVisibles: false,
      valueProduct: "",
      dialogVisibleC: false,
      optionsProduct: [
        {
          value: "1",
          type: "销售分组名称",
        },
        {
          value: "2",
          type: "企业名称",
        },
        {
          value: "3",
          type: "联系人",
        },
        {
          value: "4",
          type: "联系电话",
        },
      ],
      input: "",
      tableData: [],
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      dialogVisible: false,
      formInline: {
        name: "",
        companyName: "",
        linkman: "",
        phone: "",
        address: "",
      },
      formInlineOne: {},
      valueProduct1: "",
      optionsProduct1: [],
      valueActive: "",
      optionsActive: [],
      typeOne: "",
      isTrue: false,
      screenWidth: "",
      width: null,
    };
  },
  created() {
    this.getSalesgroup();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    codeOkTwo(data) {
      console.log(data, "查看传的接口是否正确");
      this.$nextTick(() => {
        if (data === 200) {
          this.dialogVisibles = false;
        } else {
          this.dialogVisibles = true;
        }
      });
    },
    getSalesgroup() {
      apiShout
        .getSaGroup({
          pageCount: this.pageCount,
          pageSize: this.pageSize,
          type: this.typeOne,
          value: this.input,
        })
        .then((res) => {
          console.log("销售分组：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    rowClick(row, column, event) {
      console.log(row, column, event, "row, column, event");
      this.dialogVisibleC = true;
      this.formInlineOne = row;
      api.getSelectProduct({ dictCode: "groupverisontype" }).then((res) => {
        if (res.data.code === 200) {
          this.versions = res.data.result;
        }
      });
      api.searchAllOrg().then((res) => {
        //console.log('所有机构：',res.data);
        if (res.data.code === 200) {
          this.orgIds = res.data.result;
        }
      });
      // api.getSelectProduct({dictCode:"organization"}).then(res=>{
      //   if(res.data.code === 200){
      //     this.orgIds = res.data.result;
      //   }
      // })
    },
    onSubmitOne() {
      if (!/^1[0123456789]\d{9}$/.test(this.formInlineOne.phone)) {
        this.$message.error("请输入正确的电话号码!");
        return;
      }
      // var reg =
      //   /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
      // if (!reg.test(this.formInlineOne.phone)) {
      //   this.$message.error("请输入正确的电话号码!");
      //   return;
      // }
      apiShout
        .getupGroup({
          id: this.formInlineOne.id,
          name: this.formInlineOne.name,
          companyName: this.formInlineOne.companyName,
          linkman: this.formInlineOne.linkman,
          phone: this.formInlineOne.phone,
          address: this.formInlineOne.address,
          groupConfId: this.formInlineOne.groupConfId,
          special: this.formInlineOne.special,
        })
        .then((res) => {
          console.log("修改：", res.data);
          if (res.data.code == 200) {
            this.dialogVisibleC = false;
            this.$message({
              message: "成功修改销售分组",
              type: "success",
            });
            this.getSalesgroup();
          }
        });
    },
    typeoneProduct(value) {
      console.log("type:", value);
      this.typeOne = value;
      this.input = "";
    },
    searchProduct() {
      apiShout
        .getSaGroup({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          type: this.typeOne,
          value: this.input,
        })
        .then((res) => {
          console.log("查询销售分组：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      // //清空查询的框
      // this.input = "";
      // this.valueProduct = "";
    },
    addList() {
      this.dialogVisible = true;
      api.getSelectProduct({ dictCode: "groupverisontype" }).then((res) => {
        console.log(res, "getSelectProduct");
        if (res.data.code === 200) {
          this.versions = res.data.result;
        }
      });
      api.searchAllOrg().then((res) => {
        //console.log('所有机构：',res.data);
        if (res.data.code === 200) {
          this.orgIds = res.data.result;
        }
      });
      // api.getSelectProduct({dictCode:"organization"}).then(res=>{
      //   console.log(res, "organization");
      //   if(res.data.code === 200){
      //     this.orgIds = res.data.result;
      //   }
      // })
    },
    handleEdit(index, row) {
      console.log("配置：", index, row);
      apiShout.getGroupConfs({ id: row.groupConfId }).then((res) => {
        if (res.data.code === 200) {
          this.dialogVisibles = true;
          this.groupName = row.name;
          console.log(res.data.result, "查询销售分组配置列表数据");
          localStorage.setItem("groupList", JSON.stringify(res.data.result));
          this.$nextTick(() => {
            this.$refs.configures.form = res.data.result;
            this.$refs.configures.SwitchID = "5";
            this.$refs.configures.form.region = "5";
            this.$refs.configures.form.sosPhones = res.data.result.sosPhone;
            this.$refs.configures.form.callPhoneOne = res.data.result.callPhone;
            this.$refs.configures.form.callPhoneTwo =
              res.data.result.callCustom;
            console.log(this.$refs.configures.form, "传值数据");
            if (res.data.result.sosPhone != "") {
              this.$refs.configures.customShow = true;
            } else {
              this.$refs.configures.customShow = false;
            }
            // if (res.data.result.callPhone != "") {
            //   this.$refs.configures.customShows = true;
            // } else {
            //   this.$refs.configures.customShows = false;
            // }
            if (res.data.result.callPhone != "") {
              this.$refs.configures.customShowTwos = true;
            } else {
              this.$refs.configures.customShowTwos = false;
            }
            if (this.$refs.configures.form.distrubType == "0") {
              this.$refs.configures.isShow = false;
            } else {
              this.$refs.configures.isShow = true;
            }
          });
        } else {
          this.dialogVisibles = true;
        }
      });
    },
    handleCloses(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    //每页的条数分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getSalesgroup();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getSalesgroup();
    },
    typeoneProduct1(value) {
      console.log("region:", value);
    },
    //增加的表单
    onSubmit() {
      if (!/^1[0123456789]\d{9}$/.test(this.formInline.phone)) {
        this.$message.error("请输入正确的电话号码!");
        return;
      }
      // var reg =
      //   /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
      // if (!reg.test(this.formInline.phone)) {

      // }
      apiShout.getinGroup(this.formInline).then((res) => {
        console.log("增加销售分组：", res.data);
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.$message({
            message: "增加销售分组成功",
            type: "success",
          });
          this.getSalesgroup();
          this.formInline = {
            name: "",
            companyName: "",
            linkman: "",
            phone: "",
            address: "",
            special: "",
          };
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    //数据导出
    dataExport() {
      let that = this;
      that
        .$http({
          url: "/too/center/salesgroup/exportGroupList",
          params: { type: this.typeOne, value: this.input },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          console.log("导出销售分组：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          // const objectUrl = URL.createObjectURL(blob);
          // window.location.href = objectUrl;
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "销售分组" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
  },
};
</script>

<style lang='less' scoped>
//上边
.protop {
  margin-top: 20px;
  margin-left: 25px;
  height: auto;
  margin-right: 40px;
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    color: #909399;
    margin-right: 10px;
    font-weight: 700;
  }
  // input框
  .el-input__inner {
    border-radius: 0;
    border: 1px solid #e1e1e1;
  }
  // 播放次数
  .play {
    left: 30px;
    top: 3px;
    .el-input {
      width: 134px;
    }
  }
  // 关键词
  .antistop {
    left: 700px;
    top: 3px;
    display: flex;
    align-items: center;
    .spanguan {
      margin-left: 80px;
    }
    .el-input {
      width: 230px;
    }
    .buttons {
      margin-left: 14px;
    }
  }
}
.guanjian {
  margin-left: 20px;
}
.btn-img {
  width: 49px;
}
.tan-input {
  width: 300px;
}
.tan-inputOne {
  width: 300px;
  margin-left: 30px;
}
.tan-inputTwo {
  width: 300px;
  margin-left: 45px;
}
.tan-inputThree {
  width: 300px;
  margin-left: 55px;
}
.tan-inputFour {
  width: 300px;
  margin-left: 60px;
}
.tan-que {
  margin-bottom: 20px;
  margin-left: 400px;
}
// 下边
.bottom-right {
  float: right;
  text-align: center;
  margin-right: 10px;
}
// .buttons {
//   .el-button {
//     width: 145px;
//     height: 30px;
//     border-radius: 25px;
//     margin-left: 50px;
//     margin-top: 20px;
//     background: #d78673;
//     color: #ffffff;
//     font-size: 19px;
//     padding: 0;
//     float: right;
//   }
// }
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}

.top-btn {
  background: #d78673;
  margin-left: 30px;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 10px;
}
.bottom-left {
  float: left;
}

.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
//表格
.table {
  margin-top: 20px;
  /deep/ .el-table {
    td,
    th.is-leaf {
      border: none !important;
    }
    tr th {
      font-size: 14px;
      color: #909399;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 14px;
      color: #606266;
    }
  }
}
//弹框
/deep/ .el-dialog__body {
  margin: 20px;
}
/deep/.el-input__inner {
  border-radius: 0;
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 33px;
}
/deep/.el-form-item__label {
  color: #909399;
}
/deep/ .el-dialog__body {
  min-height: 400px;
}
// .el-button--primary {
//   float: right;
//   margin: 20px;
// }
</style>