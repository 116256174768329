<template>
  <div class="topBig">
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label-width="15px">
        <el-select
          v-model="form.region"
          placeholder="请选择"
          style="width: 46%"
          @change="Switching(form.region)"
        >
          <el-option key="" label="通用" value="-1" />
          <el-option
            v-for="item in regionList"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <div v-if="SwitchID == 5">
        <configureOne @codeOk="codeOk" ref="configuress" />
      </div>
      <div v-if="SwitchID == 2">
        <ConfigureTwo @codeOk="codeOk" ref="configuresTwo" />
      </div>
      <div v-if="SwitchID == 3">
        <ConfigureThree @codeOk="codeOk" ref="configuresThree" />
      </div>
      <!-- <div v-if="SwitchID == 6">
        <ConfigureFour @codeOk="codeOk" ref="configuresFour" />
      </div> -->
      <div v-if="SwitchID == 1">
        <Configurefive @codeOk="codeOk" ref="configuresFive" />
      </div>
      <div v-if="SwitchID == 4">
        <ConfigureSix @codeOk="codeOk" ref="configuresSix" />
      </div>
      <div v-if="SwitchID == -1">
        <ConfigureSeven @codeOk="codeOk" ref="ConfigureSeven" />
      </div>
    </el-form>
  </div>
</template>

<script>
import { api } from "../../api1/config";
import Configurefive from "./configurefive.vue";
// import ConfigureFour from "./configureFour.vue";
import configureOne from "./configureOne.vue";
import ConfigureSix from "./configureSix.vue";
import ConfigureThree from "./configureThree.vue";
import ConfigureTwo from "./configureTwo.vue";
import ConfigureSeven from "./configureSeven.vue";
export default {
  components: {
    configureOne,
    ConfigureTwo,
    ConfigureThree,
    // ConfigureFour,
    Configurefive,
    ConfigureSix,
    ConfigureSeven,
  },
  data() {
    return {
      form: {
        region: "5",
        nickname: "",
        alarmInterval: "",
        gpsFrequency: "",
        uploadInterval: "",
        heartInterval: "",
        oxygenInterval: "",
        tempInterval: "",
        specialSwitch: "",
        distrubStart: "",
        distrubEnd: "",
        distrubType: "",
        sosType: "",
        sosPhone: null,
        callFunction: "",
        callPhone: null,
        // customTel: "",
        ymServices: null,
        sosPhones: null,
        callPhoneOne: null,
        callPhoneTwo: null,
        callCustom: null,
        customTel: "",
        careldServices: null,
        callContact: -1,
        healthAdvisor: null,
        legalAdvice: null,
        caringCondolen: null,
        basicServices: null,
      },
      regionList: [
        {
          id: "1",
          name: "呼叫器设置",
        },
        {
          id: "2",
          name: "安防设备设置",
        },
        {
          id: "3",
          name: "医疗设备设置",
        },
        {
          id: "4",
          name: "安防设备设置",
        },
        {
          id: "5",
          name: "适老设备设置",
        },
        {
          id: "6",
          name: "机器人设置",
        },
      ],
      SwitchID: 5,
    };
  },
  watch: {
    form(val, oldVal) {
      console.log("inputVal = " + val + " , oldValue = " + oldVal);
      console.log(this.form, "123");
      this.$nextTick(() => {
        console.log(this.SwitchID, "SwitchIDs");
        console.log(this.$refs.configuress.form, "formformformform");
        if (this.SwitchID == 5) {
          console.log("-=-=-=-=-=");
          this.$refs.configuress.form = this.form;
          this.$refs.configuress.form.sosPhones = this.form.sosPhones;
          this.$refs.configuress.form.callPhoneOne = this.form.callPhoneOne;
          this.$refs.configuress.form.callPhoneTwo = this.form.callPhoneTwo;
          this.$refs.configuress.customShow = this.customShow;
          this.$refs.configuress.customShowTwos = this.customShowTwos;
          this.$refs.configuress.isShow = this.isShow;
          this.$refs.configuress.secretKey = "";
          this.$refs.configuress.changeNum = false;
          if (this.form.otherServer == "1") {
            this.$refs.configuress.cusSystem = true;
            this.$refs.configuress.loading = true;
            this.$refs.configuress.isDisabled = false;
          } else {
            this.$refs.configuress.cusSystem = false;
            this.$refs.configuress.loading = false;
            this.$refs.configuress.isDisabled = true;
          }
          if (this.$refs.configuress.form.otherServerUrl == null) {
            this.$refs.configuress.serverUrl = "";
            this.$refs.configuress.serverUrlOne = "";
          } else {
            var aa = this.$refs.configuress.form.otherServerUrl.split(",");
            // console.log("输出逗号的值：", aa);
            this.$refs.configuress.serverUrl = aa[0];
            this.$refs.configuress.serverUrlOne = aa[1];
          }
        } else if (this.SwitchID == 2) {
          // 2
          this.$refs.configuresTwo.form = this.form;
          this.$refs.configuresTwo.form.sosPhones = this.form.sosPhones;
          this.$refs.configuresTwo.form.callPhoneOne = this.form.callPhoneOne;
          this.$refs.configuresTwo.form.callPhoneTwo = this.form.callPhoneTwo;
          this.$refs.configuresTwo.customShow = this.customShow;
          this.$refs.configuresTwo.customShowTwos = this.customShowTwos;
          this.$refs.configuresTwo.isShow = this.isShow;
        } else if (this.SwitchID == 3) {
          //3
          this.$refs.configuresThree.form = this.form;
          // this.$refs.configuresThree.formOne = this.form;
          this.$refs.configuresThree.form.sosPhones = this.form.sosPhones;
          this.$refs.configuresThree.form.callPhoneOne = this.form.callPhoneOne;
          this.$refs.configuresThree.form.callPhoneTwo = this.form.callPhoneTwo;
          this.$refs.configuresThree.customShow = this.customShow;
          this.$refs.configuresThree.customShowTwos = this.customShowTwos;
          this.$refs.configuresThree.isShow = this.isShow;
        } else if (this.SwitchID == 4) {
          //4
          this.$refs.configuresFour.form = this.form;
          this.$refs.configuresFour.form.sosPhones = this.form.sosPhones;
          this.$refs.configuresFour.form.callPhoneOne = this.form.callPhoneOne;
          this.$refs.configuresFour.form.callPhoneTwo = this.form.callPhoneTwo;
          this.$refs.configuresFour.customShow = this.customShow;
          this.$refs.configuresFour.customShowTwos = this.customShowTwos;
          this.$refs.configuresFour.isShow = this.isShow;
        } else if (this.SwitchID == 1) {
          //5
          this.$refs.configuresFive.form = this.form;
          this.$refs.configuresFive.form.sosPhones = this.form.sosPhones;
          this.$refs.configuresFive.form.callPhoneOne = this.form.callPhoneOne;
          this.$refs.configuresFive.form.callPhoneTwo = this.form.callPhoneTwo;
          this.$refs.configuresFive.customShow = this.customShow;
          this.$refs.configuresFive.customShowTwos = this.customShowTwos;
          this.$refs.configuresFive.isShow = this.isShow;
        } else if (this.SwitchID == 6) {
          //6
          this.$refs.configuresSix.form = this.form;
          this.$refs.configuresSix.form.sosPhones = this.form.sosPhones;
          this.$refs.configuresSix.form.callPhoneOne = this.form.callPhoneOne;
          this.$refs.configuresSix.form.callPhoneTwo = this.form.callPhoneTwo;
          this.$refs.configuresSix.customShow = this.customShow;
          this.$refs.configuresSix.customShowTwos = this.customShowTwos;
          this.$refs.configuresSix.isShow = this.isShow;
        }
        console.log(this.form.sosPhones, "sosppppp1");
        console.log(this.form.callPhoneOne, "callPhoneOne1");
        console.log(this.form.callPhoneTwo, "form.callPhoneTwo1");
        console.log(this.customShow, "customShow1");
        console.log(this.customShowTwos, "customShowTwos1");
        console.log(this.isShow, "isShow1");
      });
    },
  },
  mounted() {
    api.getSelectProduct({ dictCode: "producttype" }).then((res) => {
      console.log("下拉2346666666：", res.data);
      if (res.data.code == 200) {
        this.regionList = res.data.result;
      }
    });
  },
  methods: {
    codeOk(data) {
      console.log(data, "查看传的接口是否正确");
      this.$emit("codeOkTwo", data);
    },
    Switching(data) {
      this.SwitchID = data;
      console.log(data, "data");
      // this.$refs.configuress.form = this.form;
    },
  },
};
</script>
<style lang="less" scoped>
.topBig {
  width: 95%;
  margin-left: 2.5%;
  padding: 10px 0;
  overflow-y: scroll;
  scrollbar-width: none;
}
/deep/ .el-form-item {
  margin-bottom: 15px;
}
</style>