<template>
  <div>
    <div style="padding: 0px 0; border-bottom: 1px solid #000">
      <div class="transfer">
        <!-- 左框 -->
        <ul class="list left">
          <template v-for="(item, index) in info">
            <li
              :key="index"
              v-bind:class="{ changeColor: index == currentIndex }"
              @click="infoBtn(item, index)"
            >
              <div @click="item.select = !item.select">
                <div v-if="item.rbtYmsos">紧急救援</div>
                <div v-if="item.rbtCall">菜单打电话</div>
                <div v-if="item.rbtVideo">菜单视频聊天</div>
                <div v-if="item.rbtHltData">菜单健康数据</div>
                <div v-if="item.rbtHltKnlg">菜单养生知识</div>
                <div v-if="item.rbtHltServ">菜单健康顾问</div>
                <div v-if="item.rbtPhoto">菜单家庭相册</div>
                <div v-if="item.rbtRadio">菜单收音机</div>
                <div v-if="item.rbtLevmsg">留言</div>
                <div v-if="item.rbtClock">菜单闹钟计时</div>
                <div v-if="item.rbtRemind">菜单提醒备忘</div>
                <div v-if="item.rbtSet">菜单设置</div>
              </div>
            </li>
          </template>
        </ul>

        <!-- 添加/删除 -->
        <div class="content">
          <p
            class="push"
            @click="push"
            style="margin-bottom: 0; font-size: 18px"
          >
            <img style="width: 40%" src="../../assets/rightjt.png" />
          </p>
          <p class="del" @click="del" style="margin-bottom: 0; font-size: 18px">
            <img style="width: 40%" src="../../assets/leftjt.png" />
          </p>
        </div>

        <!-- 右框 -->
        <ul class="list right">
          <template v-for="(item, index) in new_info">
            <li
              :key="index"
              v-bind:class="{ changeColor: index == currentIndexs }"
              @click="infoBtns(item, index)"
            >
              <div @click="item.select = !item.select">
                <!-- <span :for="`newcheckbox${item.id}`">{{ item.name }} </span> -->
                <div v-if="item.rbtYmsos">紧急救援</div>
                <div v-if="item.rbtCall">菜单打电话</div>
                <div v-if="item.rbtVideo">菜单视频聊天</div>
                <div v-if="item.rbtHltData">菜单健康数据</div>
                <div v-if="item.rbtHltKnlg">菜单养生知识</div>
                <div v-if="item.rbtHltServ">菜单健康顾问</div>
                <div v-if="item.rbtPhoto">菜单家庭相册</div>
                <div v-if="item.rbtRadio">菜单收音机</div>
                <div v-if="item.rbtLevmsg">留言</div>
                <div v-if="item.rbtClock">菜单闹钟计时</div>
                <div v-if="item.rbtRemind">菜单提醒备忘</div>
                <div v-if="item.rbtSet">菜单设置</div>
              </div>
              <!-- <input
                type="checkbox"
                :id="`newcheckbox${item.id}`"
                name="newcheckbox"
                :checked="item.select"
                @click="item.select = !item.select"
              />
              <label :for="`newcheckbox${item.id}`">{{ item.name }} </label> -->
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div style="border-bottom: 1px solid #000; margin-top: 3%">
      <el-form-item label="SOS号码设置">
        <el-select
          style="float: left"
          v-model="form.rbtSosType"
          placeholder="请选择"
          @input="$forceUpdate()"
          @change="engryCanSos(form.rbtSosType)"
        >
          <el-option
            v-for="item in SosOne"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
        <el-input
          style="width: 30%; margin-left: 3%"
          v-if="customShow"
          v-model="form.rbtSosPhone"
          @input="$forceUpdate()"
          @change="engryCanSosS(form.rbtSosPhone)"
        ></el-input>
      </el-form-item>
      <el-form-item label="呼叫功能">
        <el-select
          style="float: left"
          v-model="form.rbtCallFunction"
          placeholder="请选择"
          @input="$forceUpdate()"
          @change="engryCanCall(form.rbtCallFunction)"
        >
          <el-option
            v-for="item in callOne"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
        <!-- <el-input
            style="width: 30%; margin-left: 3%"
            v-if="customShows"
            v-model="form.callPhone"
            @input="$forceUpdate()"
            @change="engryCanCalls(form.callPhone)"
          ></el-input> -->
        <el-input
          style="width: 30%; margin-left: 3%"
          v-if="customShowTwos"
          v-model="form.rbtCallPhone"
          @input="$forceUpdate()"
          @change="engryCanCallsOne(form.rbtCallPhone)"
        ></el-input>
      </el-form-item>
      <el-form-item label="允许换卡">
        <el-select
          v-model="form.rbtCustomTel"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in changeCard"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="免打扰设置">
        <div style="display: inline-block" v-if="isShow">
          <el-time-picker
            placeholder="请选择时间"
            v-model="form.rbtDistrubStart"
            :picker-options="{
              selectableRange: `${
                form.rbtDistrubEnd ? form.rbtDistrubEnd + ':00' : '00:00:00'
              }-23:59:59`,
            }"
            value-format="HH:mm"
            format="HH:mm"
            @input="$forceUpdate()"
            @change="startCan(form.rbtDistrubStart)"
          >
          </el-time-picker>
          -
          <el-time-picker
            placeholder="请选择时间"
            v-model="form.rbtDistrubEnd"
            :picker-options="{
              selectableRange: `00:00:00-${
                form.rbtDistrubStart ? form.rbtDistrubStart + ':00' : '23:59:59'
              }`,
            }"
            value-format="HH:mm"
            format="HH:mm"
            @input="$forceUpdate()"
            @change="endCan(form.rbtDistrubEnd)"
          >
          </el-time-picker>
        </div>
        <el-select
          style="width: 29%; margin-top: -4px"
          v-model="form.rbtDistrubType"
          placeholder="请选择"
          @input="$forceUpdate()"
          @change="patternCan(form.rbtDistrubType)"
        >
          <el-option
            v-for="item in patternStatus"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label-width="0px" style="text-align: center">
          <el-button type="primary" @click="onSubmit" style="padding: 14px 50px"
            >保 存</el-button
          >
        </el-form-item> -->
    </div>
    <!-- <div style="color: #d78673; font-size: 18px; margin-top: 3%">
      智能呼叫器:
    </div> -->
    <div
      style="
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
        margin-top: 2%;
      "
    >
      <el-form-item label="客服服务" style="width: 33.3%">
        <el-select
          v-model="form.rbtCareldServices"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="远盟服务" style="width: 33.3%">
        <el-select
          v-model="form.rbtYmServices"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="呼叫联系人" style="width: 33.3%">
        <el-select
          v-model="form.rbtCallContact"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
          disabled
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="健康顾问" style="width: 33.3%">
        <el-select
          v-model="form.rbtHealthAdvisor"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="法律咨询" style="width: 33.3%">
        <el-select
          v-model="form.rbtLegalAdvice"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关爱慰问" style="width: 33.3%">
        <el-select
          v-model="form.rbtCaringCondolen"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="基础服务" style="width: 33.3%">
        <el-select
          v-model="form.rbtBasicServices"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div
      style="
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
      "
    >
      <el-form-item label-width="0px" style="text-align: center">
        <el-button
          type="primary"
          @click="onSubmit"
          style="padding: 14px 50px; margin-left: 300px"
          >保 存</el-button
        >
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      currentIndex: null,
      currentIndexs: null,
      form: {
        region: "",
        nickname: "",
        alarmInterval: "",
        gpsFrequency: "",
        uploadInterval: "",
        heartInterval: "",
        oxygenInterval: "",
        tempInterval: "",
        specialSwitch: "",
        rbtDistrubStart: "",
        rbtDistrubEnd: "",
        rbtDistrubType: "",
        rbtSosType: "",
        rbtSosPhone: null,
        rbtCallFunction: "",
        rbtCallPhone: null,
        // customTel: "",
        rbtYmServices: null,
        rbtSosPhones: null,
        rbtCallPhoneOne: null,
        rbtCallPhoneTwo: null,
        callCustom: null,
        rbtCustomTel: "",
        rbtCareldServices: null,
        rbtCallContact: -1,
        rbtHealthAdvisor: null,
        rbtLegalAdvice: null,
        rbtCaringCondolen: null,
        rbtBasicServices: null,
      },
      isShow: true,
      changeCard: [],
      customerService: [
        {
          code: 0,
          name: "无",
        },
        {
          code: -1,
          name: "永久",
        },
        {
          code: 1,
          name: "1个月",
        },
        {
          code: 2,
          name: "2个月",
        },
        {
          code: 3,
          name: "3个月",
        },
        {
          code: 4,
          name: "4个月",
        },
        {
          code: 5,
          name: "5个月",
        },
        {
          code: 6,
          name: "6个月",
        },
        {
          code: 7,
          name: "7个月",
        },
        {
          code: 8,
          name: "8个月",
        },
        {
          code: 9,
          name: "9个月",
        },
        {
          code: 10,
          name: "10个月",
        },
        {
          code: 11,
          name: "11个月",
        },
        {
          code: 12,
          name: "12个月",
        },
      ],
      regionName: [],
      callPolices: [
        {
          value: "1",
          label: "01小时",
        },
        {
          value: "3",
          label: "03小时",
        },
        {
          value: "6",
          label: "06小时",
        },
        {
          value: "12",
          label: "12小时",
        },
        {
          value: "24",
          label: "24小时",
        },
      ],
      GpsOne: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      dataUploads: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      heartRates: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      bloodOxygens: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      temperatures: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      switchs: [
        {
          value: "0",
          label: "关",
        },
        {
          value: "1",
          label: "开",
        },
      ],
      customNumbers: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],
      serviceOpens: [
        {
          value: "0",
          label: "关",
        },
        {
          value: "1",
          label: "开",
        },
      ],
      patternStatus: [
        {
          code: "0",
          name: "关",
        },
        {
          code: "1",
          name: "开",
        },
      ],
      SosOne: [],
      callOne: [],
      shide: false,
      customShow: false,
      customShows: false,
      customShowTwos: false,
      valtel: "",
      valtels: "",
      valtelTwo: "",
      engCSeven: "",
      engCSevenOne: "",
      engCSevenTwo: "",
      codeOk: null,
      // 原数据，左框数据
      info: [],
      new_info: [], // 新数据，右框数据
      pushItem: {},
      pushIndex: null,
      rbtList: {},
    };
  },
  created() {
    this.selectName();
    // this.selectStatus();
    this.selectSos();
    this.selectCall();
    this.yes();
    console.log(this.form.rbtSosPhones, "sosppppp2");
    console.log(this.form.rbtCallPhoneOne, "rbtCallPhoneOne2");
    console.log(this.form.rbtCallPhoneTwo, "form.rbtCallPhoneTwo2");
    console.log(this.customShow, "customShow2");
    console.log(this.customShowTwos, "customShowTwos2");
    console.log(this.isShow, "isShow2");
    console.log(this.form, "formsss");
    let groupList = JSON.parse(localStorage.getItem("groupList"));
    console.log("shuchu222222:", groupList);
    this.form = groupList;
    if (this.form.rbtSosType == 3) {
      this.customShow = true;
    } else {
      this.customShow = false;
    }
    if (this.form.rbtCallFunction == 4) {
      this.customShowTwos = true;
    } else {
      this.customShowTwos = false;
    }
    if (this.form.rbtDistrubType == 1) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  },
  watch: {
    form(data) {
      console.log(data, "datachaxunyix");
      var rbtArr = [];
      let rbtYmsoss = {};
      rbtYmsoss.rbtYmsos = data.rbtYmsos;
      rbtYmsoss.rbtYmsosNo = data.rbtYmsosNo;
      rbtYmsoss.itemid = 1;
      rbtYmsoss.numNo = data.rbtYmsosNo;
      rbtArr.push(rbtYmsoss);
      let rbtCalls = {};
      rbtCalls.rbtCall = data.rbtCall;
      rbtCalls.rbtCallNo = data.rbtCallNo;
      rbtCalls.itemid = 2;
      rbtCalls.numNo = data.rbtCallNo;
      rbtArr.push(rbtCalls);
      let rbtVideos = {};
      rbtVideos.rbtVideo = data.rbtVideo;
      rbtVideos.rbtVideoNo = data.rbtVideoNo;
      rbtVideos.itemid = 3;
      rbtVideos.numNo = data.rbtVideoNo;
      rbtArr.push(rbtVideos);
      let rbtHltDatas = {};
      rbtHltDatas.rbtHltData = data.rbtHltData;
      rbtHltDatas.rbtHltDataNo = data.rbtHltDataNo;
      rbtHltDatas.itemid = 4;
      rbtHltDatas.numNo = data.rbtHltDataNo;
      rbtArr.push(rbtHltDatas);
      let rbtHltKnlgs = {};
      rbtHltKnlgs.rbtHltKnlg = data.rbtHltKnlg;
      rbtHltKnlgs.rbtHltKnlgNo = data.rbtHltKnlgNo;
      rbtHltKnlgs.itemid = 5;
      rbtHltKnlgs.numNo = data.rbtHltKnlgNo;
      rbtArr.push(rbtHltKnlgs);
      let rbtHltServs = {};
      rbtHltServs.rbtHltServ = data.rbtHltServ;
      rbtHltServs.rbtHltServNo = data.rbtHltServNo;
      rbtHltServs.itemid = 6;
      rbtHltServs.numNo = data.rbtHltServNo;
      rbtArr.push(rbtHltServs);
      let rbtPhotos = {};
      rbtPhotos.rbtPhoto = data.rbtPhoto;
      rbtPhotos.rbtPhotoNo = data.rbtPhotoNo;
      rbtPhotos.itemid = 7;
      rbtPhotos.numNo = data.rbtPhotoNo;
      rbtArr.push(rbtPhotos);
      let rbtRadios = {};
      rbtRadios.rbtRadio = data.rbtRadio;
      rbtRadios.rbtRadioNo = data.rbtRadioNo;
      rbtRadios.itemid = 8;
      rbtRadios.numNo = data.rbtRadioNo;
      rbtArr.push(rbtRadios);
      let rbtLevmsgs = {};
      rbtLevmsgs.rbtLevmsg = data.rbtLevmsg;
      rbtLevmsgs.rbtLevmsgNo = data.rbtLevmsgNo;
      rbtLevmsgs.itemid = 9;
      rbtLevmsgs.numNo = data.rbtLevmsgNo;
      rbtArr.push(rbtLevmsgs);
      let rbtClocks = {};
      rbtClocks.rbtClock = data.rbtClock;
      rbtClocks.rbtClockNo = data.rbtClockNo;
      rbtClocks.itemid = 10;
      rbtClocks.numNo = data.rbtClockNo;
      rbtArr.push(rbtClocks);
      let rbtReminds = {};
      rbtReminds.rbtRemind = data.rbtRemind;
      rbtReminds.rbtRemindNo = data.rbtRemindNo;
      rbtReminds.itemid = 11;
      rbtReminds.numNo = data.rbtRemindNo;
      rbtArr.push(rbtReminds);
      let rbtSets = {};
      rbtSets.rbtSet = data.rbtSet;
      rbtSets.rbtSetNo = data.rbtSetNo;
      rbtSets.itemid = 12;
      rbtSets.numNo = data.rbtSetNo;
      rbtArr.push(rbtSets);
      console.log(rbtArr, "rbtArrrbtArrrbtArr");

      var infos = [];
      var new_info = [];
      rbtArr.forEach((item) => {
        if (item.rbtYmsos == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtYmsos == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtCall == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtCall == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtVideo == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtVideo == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtHltData == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtHltData == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtHltKnlg == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtHltKnlg == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtHltServ == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtHltServ == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtPhoto == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtPhoto == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtRadio == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtRadio == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtLevmsg == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtLevmsg == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtClock == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtClock == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtRemind == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtRemind == 0) {
          infos.push(item); //关的数据
        }
        if (item.rbtSet == "1") {
          new_info.push(item); //开的数据
        } else if (item.rbtSet == 0) {
          infos.push(item); //关的数据
        }
      });
      console.log(infos, "leftArr");
      console.log(new_info, "rightArr");
      console.log(new_info, "new_info000");
      infos.forEach((item, index) => {
        console.log(item, index, "itemindex");
        this.info = infos.sort((a, b) => {
          return a.numNo - b.numNo;
        }); // 添加到新数据框, 排序
      });
      new_info.forEach((item, index) => {
        console.log(item, index, "itemindex");
        this.new_info = new_info.sort((a, b) => {
          return a.numNo - b.numNo;
        }); // 添加到新数据框, 排序
      });
      console.log(this.new_info, "00000");
    },
  },
  mounted() {
    let that = this;
    console.log("hoqushuju", that.info);
    // 给原始数据添加一个 select 字段，判断是否选中
    that.info.map((val, key) => {
      console.log("hoqushuju");
      console.log(key, "key");
      console.log(val, "val");
      that.$set(val, "select", false);
      that.$set(val, "itemId", key);
      // that.info = that.info.concat(val).sort((a, b) => {
      //   console.log('00000')
      //     return a.serial - b.serial;
      //   }); // 添加到新数据框, 排序
    });
    that.new_info.map((val, key) => {
      console.log("hoqushuju");
      console.log(key, "key");
      console.log(val, "val");
      that.$set(val, "select", false);
      that.$set(val, "itemId", key);
      // that.info = that.info.concat(val).sort((a, b) => {
      //   console.log('00000')
      //     return a.serial - b.serial;
      //   }); // 添加到新数据框, 排序
    });
  },
  methods: {
    formListK(data) {
      console.log(data, "datalist");
    },
    subimtBtn() {
      console.log(this.info, "info查看顺序");
      console.log(this.new_info, "new_info查看顺序");
      this.info.forEach((items) => {
        if (items.rbtYmsos) {
          items.rbtYmsos = "0";
          console.log("这里");
        }
        if (items.rbtCall) {
          items.rbtCall = "0";
          console.log("这里22");
        }
        if (items.rbtVideo) {
          items.rbtVideo = "0";
          console.log("这里33");
        }
        if (items.rbtHltData) {
          items.rbtHltData = "0";
          console.log("这里44");
        }
        if (items.rbtHltKnlg) {
          items.rbtHltKnlg = "0";
          console.log("这里55");
        }
        if (items.rbtHltServ) {
          items.rbtHltServ = "0";
          console.log("这里66");
        }
        if (items.rbtPhoto) {
          items.rbtPhoto = "0";
          console.log("这里77");
        }
        if (items.rbtRadio) {
          items.rbtRadio = "0";
          console.log("这里88");
        }
        if (items.rbtLevmsg) {
          items.rbtLevmsg = "0";
          console.log("这里99");
        }
        if (items.rbtClock) {
          items.rbtClock = "0";
          console.log("这里10");
        }
        if (items.rbtRemind) {
          items.rbtRemind = "0";
          console.log("这里11");
        }
        if (items.rbtSet) {
          items.rbtSet = "0";
          console.log("这里12");
        }
      });
      this.new_info.forEach((items, index) => {
        console.log(items);
        if (items.itemid == 1) {
          items.rbtYmsosNo = index + 1;
        } else if (items.itemid == 2) {
          items.rbtCallNo = index + 1;
        } else if (items.itemid == 3) {
          items.rbtVideoNo = index + 1;
        } else if (items.itemid == 4) {
          items.rbtHltDataNo = index + 1;
        } else if (items.itemid == 5) {
          items.rbtHltKnlgNo = index + 1;
        } else if (items.itemid == 6) {
          items.rbtHltServNo = index + 1;
        } else if (items.itemid == 7) {
          items.rbtPhotoNo = index + 1;
        } else if (items.itemid == 8) {
          items.rbtRadioNo = index + 1;
        } else if (items.itemid == 9) {
          items.rbtLevmsgNo = index + 1;
        } else if (items.itemid == 10) {
          items.rbtClockNo = index + 1;
        } else if (items.itemid == 11) {
          items.rbtRemindNo = index + 1;
        } else if (items.itemid == 12) {
          items.rbtSetNo = index + 1;
        }
        if (items.rbtYmsos) {
          console.log("进入1");
          items.rbtYmsos = "1";
        }
        if (items.rbtCall) {
          console.log("进入2");
          items.rbtCall = "1";
        }
        if (items.rbtVideo) {
          console.log("进入3");
          items.rbtVideo = "1";
        }
        if (items.rbtHltData) {
          console.log("进入4");
          items.rbtHltData = "1";
        }
        if (items.rbtHltKnlg) {
          console.log("进入5");
          items.rbtHltKnlg = "1";
        }
        if (items.rbtHltServ) {
          console.log("进入6");
          items.rbtHltServ = "1";
        }
        if (items.rbtPhoto) {
          console.log("进入7");
          items.rbtPhoto = "1";
        }
        if (items.rbtRadio) {
          console.log("进入8");
          items.rbtRadio = "1";
        }
        if (items.rbtLevmsg) {
          console.log("进入9");
          items.rbtLevmsg = "1";
        }
        if (items.rbtClock) {
          console.log("进入10");
          items.rbtClock = "1";
        }
        if (items.rbtRemind) {
          console.log("进入11");
          items.rbtRemind = "1";
        }
        if (items.rbtSet) {
          console.log("进入12");
          items.rbtSet = "1";
        }
      });
      let arrAyList = this.info.concat(this.new_info);
      console.log(arrAyList, "arrAyList");
      let arr = {};
      arrAyList.forEach((res) => {
        arr = Object.assign(arr, res);
      });
      console.log(arr, "arrarrarr");
      this.$delete(arr, "numNo");
      this.$delete(arr, "itemid");
      this.$delete(arr, "select");
      console.log(arr);
      this.rbtList = arr;
      // arrAyList.forEach((itemTwo) => {
      //   let itemObjs = {};
      //   itemObjs = itemTwo;
      //   console.log(itemObjs, "itemObjs");
      // });
      // this.info.concat(item).sort((a, b) => {
      //       return a.id - b.id;
      //     }); // 添加到新数据框, 排序
      console.log(this.info, "info2查看顺序");
      console.log(this.new_info, "new_info2查看顺序");
    },
    infoBtn(item, index) {
      console.log(item, index, "查询一下");
      this.currentIndex = index;
      // this.pushItem = item;
      // this.pushIndex = index;
      this.info.map((val, key) => {
        console.log(key, "key");
        console.log(val, "val");
        this.$set(val, "select", false);
      });
      item.select = !item.select;
    },
    infoBtns(item, index) {
      console.log(item, index, "查询一下");
      this.currentIndexs = index;
      this.new_info.map((val, key) => {
        console.log(key, "key");
        console.log(val, "val");
        this.$set(val, "select", false);
      });
      item.select = !item.select;
    },
    push() {
      let that = this;
      let info = JSON.parse(JSON.stringify(that.info)); // 拷贝原数据, 深拷贝
      console.log("000000");
      info.forEach((item, index) => {
        console.log(item, index, "itemindex");
        // 执行 select 为true 的数据
        if (item.select) {
          console.log(item, "item点击的是哪一个");
          that.new_info.push(item);
          that.new_info.concat(item).sort((a, b) => {
            return a.id - b.id;
          }); // 添加到新数据框, 排序
          // that.new_info.unshift(that.new_info);
          delete info[index]; // 删除数据
          item.select = false;
        }
      });
      this.currentIndex = null;
      info = info.filter(function (val) {
        return val;
      }); // 过滤 undefined
      that.info = info; // 更新原数据\
      // delete this.pushItem;
      // this.pushItem = undefined;
      // console.log(this.pushItem, "this.pushItem");
    },
    // 移除数据
    del() {
      let that = this;
      let info = JSON.parse(JSON.stringify(that.new_info)); // 拷贝原数据, 深拷贝
      info.forEach((item, index) => {
        console.log(item, index, "itemindex");
        // 执行 select 为true 的数据
        if (item.select) {
          console.log(item, "rightitem点击的是哪一个");
          that.info.push(item);
          that.info.concat(item).sort((a, b) => {
            return a.id - b.id;
          }); // 添加到新数据框, 排序
          // that.info = that.info.concat(item).sort((a, b) => {
          //   return a.id - b.id;
          // }); // 添加到新数据框, 排序
          delete info[index]; // 删除数据
          item.select = false;
          console.log(that.info, "that.info8888");
        }
      });
      this.currentIndexs = null;
      info = info.filter(function (val) {
        return val;
      }); // 过滤 undefined
      that.new_info = info; // 更新原数据
    },
    selectName() {
      api.getSelectProduct({ dictCode: "calldevicename" }).then((res) => {
        //console.log('下拉2：',res.data);
        if (res.data.code == 200) {
          // console.log(res.data, "下拉结果");
          this.regionName = res.data.result;
        }
      });
    },
    // selectStatus() {
    //   api.getSelectProduct({ dictCode: "disturbtype" }).then((res) => {
    //     if (res.data.code == 200) {
    //       // console.log(res.data, "下拉结果");
    //       this.patternStatus = res.data.result;
    //     }
    //   });
    // },
    yes() {
      api.getSelectProduct({ dictCode: "isno" }).then((res) => {
        // console.log(res, "isno");
        if (res.data.code === 200) {
          this.changeCard = res.data.result;
          // this.customerService = res.data.result;
        }
      });
    },
    startCan(val) {
      this.shide = true;
      this.$forceUpdate();
      console.log(val, "开始时间");
    },
    endCan(val) {
      this.shide = true;
      this.$forceUpdate();
      console.log(val, "结束时间");
    },
    patternCan(val) {
      this.shide = true;
      this.$forceUpdate();
      console.log(val, "免打扰震动");
      if (val == "1") {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    selectSos() {
      api.getSelectProduct({ dictCode: "sosnumbertype" }).then((res) => {
        if (res.data.code == 200) {
          // console.log(res.data, "下拉结果");
          this.SosOne = res.data.result;
        }
      });
    },
    engryCanSos(val) {
      if (val === "3") {
        this.customShow = true;
        this.form.rbtSosPhone = this.rbtSosPhones;
      } else {
        this.customShow = false;
        this.form.rbtSosPhone = "";
      }
      this.engCSix = val;
      this.$forceUpdate();
      console.log(val, "sos");
    },
    engryCanSosS(val) {
      this.engCSixOne = val;
      this.valtels = val;
      this.$forceUpdate();
      console.log(val, "自定义号码");
    },
    selectCall() {
      api.getSelectProduct({ dictCode: "callfunctiontype" }).then((res) => {
        if (res.data.code == 200) {
          // console.log(res.data, "下拉结果");
          this.callOne = res.data.result;
        }
      });
    },
    engryCanCall(val) {
      // if (val === "3") {
      //   this.customShows = true;
      //   this.form.callPhone = this.callPhoneOne;
      // } else {
      //   this.customShows = false;
      //   this.form.callPhone = "";
      // }
      if (val === "4") {
        this.customShowTwos = true;
        // this.form.callCustom = this.callPhoneTwo;
        this.form.rbtCallPhone = this.rbtCallPhoneOne;
      } else {
        this.customShowTwos = false;
        // this.form.callCustom = "";
        this.form.rbtCallPhone = "";
      }
      this.engCSeven = val;
      this.$forceUpdate();
      console.log(val, "呼叫功能");
    },
    engryCanCalls(val) {
      this.engCSevenOne = val;
      this.valtel = val;
      this.$forceUpdate();
      console.log(val, "自定义号码2");
    },
    engryCanCallsOne(val) {
      this.$forceUpdate();
      console.log(val, "自定义号码3");
      this.engCSevenTwo = val;
      this.valtelTwo = val;
    },
    onSubmit() {
      this.$confirm("是否继续保存?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.subimtBtn();
          if (this.engCSix === "1" || this.engCSix === "2") {
            this.engCSixOne = "";
          } else if (this.engCSix === "3") {
            this.engCSixOne = this.rbtSosPhones || this.valtels;
          }
          if (this.engCSeven === "1" || this.engCSeven === "2") {
            this.engCSevenOne = "";
            this.engCSevenTwo = "";
          } else if (this.engCSeven === "3") {
            this.engCSevenOne = this.rbtCallPhoneOne || this.valtel;
            this.engCSevenTwo = "";
          } else if (this.engCSix === "4") {
            this.engCSevenTwo = this.rbtCallPhoneTwo || this.valtelTwo;
            this.engCSevenOne = "";
          }
          console.log(this.shide, "查看是否出发了免打扰设置");
          (this.form.createDate = []),
            (this.form.createId = []),
            (this.form.updateDate = []),
            (this.form.updateId = []),
            (this.form.delFlag = null);
          if (this.shide == false) {
            (this.form.rbtDistrubStart = null),
              (this.form.rbtDistrubEnd = null),
              (this.form.rbtDistrubType = null);
          }
          this.form.type = 4;
          let arrs = {};
          arrs = Object.assign(this.form, this.rbtList);
          console.log(arrs, "保存");
          // console.log(this.form, "查看传过去的字段");
          let obj = {
            id: this.form.id,
            type: this.form.type,
            rbtBasicServices: this.form.rbtBasicServices,
            rbtCall: this.form.rbtCall,
            rbtCallContact: this.form.rbtCallContact,
            rbtCallFunction: this.form.rbtCallFunction,
            rbtCallNo: this.form.rbtCallNo,
            rbtCallPhone: this.form.rbtCallPhone,
            rbtCareldServices: this.form.rbtCareldServices,
            rbtCaringCondolen: this.form.rbtCaringCondolen,
            rbtClock: this.form.rbtClock,
            rbtClockNo: this.form.rbtClockNo,
            rbtCustomTel: this.form.rbtCustomTel,
            rbtDistrubEnd: this.form.rbtDistrubEnd,
            rbtDistrubStart: this.form.rbtDistrubStart,
            rbtDistrubType: this.form.rbtDistrubType,
            rbtDoctorPhone: this.form.rbtDoctorPhone,
            rbtHealthAdvisor: this.form.rbtHealthAdvisor,
            rbtHltData: this.form.rbtHltData,
            rbtHltDataNo: this.form.rbtHltDataNo,
            rbtHltKnlg: this.form.rbtHltKnlg,
            rbtHltKnlgNo: this.form.rbtHltKnlgNo,
            rbtHltServ: this.form.rbtHltServ,
            rbtHltServNo: this.form.rbtHltServNo,
            rbtLegalAdvice: this.form.rbtLegalAdvice,
            rbtLevmsg: this.form.rbtLevmsg,
            rbtLevmsgNo: this.form.rbtLevmsgNo,
            rbtPhoto: this.form.rbtPhoto,
            rbtPhotoNo: this.form.rbtPhotoNo,
            rbtRadio: this.form.rbtRadio,
            rbtRadioNo: this.form.rbtRadioNo,
            rbtRemind: this.form.rbtRemind,
            rbtRemindNo: this.form.rbtRemindNo,
            rbtSet: this.form.rbtSet,
            rbtSetNo: this.form.rbtSetNo,
            rbtSosPhone: this.form.rbtSosPhone,
            rbtSosType: this.form.rbtSosType,
            rbtVideo: this.form.rbtVideo,
            rbtVideoNo: this.form.rbtVideoNo,
            rbtYmServices: this.form.rbtYmServices,
            rbtYmsos: this.form.rbtYmsos,
            rbtYmsosNo: this.form.rbtYmsosNo,
          };
          // console.log(obj, "查看传过去的字段233444444");
          apiShout.updateGroupConfs(obj).then((res) => {
            this.codeOk = res.data.code;
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.$emit("codeOk", this.codeOk);
              console.log(res, "查看修改的数据");
              //   this.$nextTick(() => {
              //     this.$parent.$parent.$parent.dialogVisibles = false;
              //     console.log(
              //       this.$parent.$parent.$parent.dialogVisibles,
              //       "查看是否关闭"
              //     );
              //   });
              if (this.shide == true) {
                this.shide = false;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style>
.transfer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 3%;
}
.transfer > .list {
  width: 390px;
  height: auto;
  border: 1px solid #000;
  list-style: none;
}
.content {
  font-size: 30px;
  margin: 0 20px;
  text-align: center;
}
.list > li {
  padding: 5px;
  box-sizing: border-box;
}
.Backcolor {
  background: #ccc;
}
.Backcolors {
  background: #fff;
}
.changeColor {
  background: #ccc;
}
</style>