<template>
  <div>
    <div style="padding: 0px 0; border-bottom: 1px solid #000">
      <div>
        <div style="display: flex">
          <el-form-item label="设备直连客户系统" label-width="130px">
            <el-switch
              v-model="cusSystem"
              active-color="#d78673"
              inactive-color="rgb(185, 182, 182)"
              @change="switchChange"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            label="秘钥"
            label-width="130px"
            style="margin-left: 13px"
          >
            <el-input
              type="text"
              v-model="secretKey"
              placeholder="请输入秘钥"
              style="width: 150px"
              clearable
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item label="客户服务器地址" label-width="130px">
          <el-input
            v-model="serverUrl"
            placeholder="请输入域名"
            style="width: 150px"
            clearable
            :disabled="isDisabled"
          ></el-input>
          <span class="colon">:</span>
          <el-input
            v-model="serverUrlOne"
            placeholder="请输入端口"
            style="width: 150px"
            clearable
            @input="inputTwo"
            :disabled="isDisabled"
          ></el-input>
        </el-form-item>
      </div>
    </div>
    <div
      v-loading="loading"
      element-loading-text=""
      element-loading-spinner="none"
      element-loading-background="rgb(221, 221, 223)"
    >
      <div
        style="
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          padding-top: 3%;
          border-bottom: 1px solid rgb(0, 0, 0);
        "
      >
        <el-form-item label="呼叫器称呼">
          <el-select
            v-model="form.nickname"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in regionName"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报警间隔">
          <el-select
            v-model="form.alarmInterval"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in callPolices"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div
        style="
          padding: 0px;
          border-bottom: 1px solid rgb(0, 0, 0);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 3%;
          justify-content: space-between;
        "
      >
        <!-- <el-form-item label="数据上传间隔">
          <el-select
            v-model="form.uploadInterval"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in dataUploads"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="心率上传间隔">
          <el-select
            v-model="form.heartInterval"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in heartRates"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="血氧上传间隔">
          <el-select
            v-model="form.oxygenInterval"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in bloodOxygens"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="体温上传间隔">
          <el-select
            v-model="form.tempInterval"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in temperatures"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="GPS定位频率">
          <el-select
            v-model="form.gpsFrequency"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in GpsOne"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="特殊功能开关">
          <el-select
            v-model="form.specialSwitch"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in switchs"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="免打扰设置">
          <div style="display: inline-block" v-if="isShow">
            <el-time-picker
              placeholder="请选择时间"
              v-model="form.distrubStart"
              :picker-options="{
                selectableRange: `${
                  form.distrubEnd ? form.distrubEnd + ':00' : '00:00:00'
                }-23:59:59`,
              }"
              value-format="HH:mm"
              format="HH:mm"
              @input="$forceUpdate()"
              @change="startCan(form.distrubStart)"
            >
            </el-time-picker>
            -
            <el-time-picker
              placeholder="请选择时间"
              v-model="form.distrubEnd"
              :picker-options="{
                selectableRange: `00:00:00-${
                  form.distrubStart ? form.distrubStart + ':00' : '23:59:59'
                }`,
              }"
              value-format="HH:mm"
              format="HH:mm"
              @input="$forceUpdate()"
              @change="endCan(form.distrubEnd)"
            >
            </el-time-picker>
          </div>
          <el-select
            style="width: 29%; margin-left: 15px; margin-top: -4px"
            v-model="form.distrubType"
            placeholder="请选择"
            @input="$forceUpdate()"
            @change="patternCan(form.distrubType)"
          >
            <el-option
              v-for="item in patternStatus"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="佩戴检测">
          <el-select
            v-model="form.removeState"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in patternStatus"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div style="border-bottom: 1px solid #000; margin-top: 3%">
        <el-form-item label="SOS号码设置">
          <el-select
            style="float: left"
            v-model="form.sosType"
            placeholder="请选择"
            @input="$forceUpdate()"
            @change="engryCanSos(form.sosType)"
          >
            <el-option
              v-for="item in SosOne"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-input
            style="width: 30%; margin-left: 3%"
            v-if="customShow"
            v-model="form.sosPhone"
            @input="$forceUpdate()"
            @change="engryCanSosS(form.sosPhone)"
          ></el-input>
        </el-form-item>
        <el-form-item label="呼叫功能">
          <el-select
            style="float: left"
            v-model="form.callFunction"
            placeholder="请选择"
            @input="$forceUpdate()"
            @change="engryCanCall(form.callFunction)"
          >
            <el-option
              v-for="item in callOne"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
          <!-- <el-input
            style="width: 30%; margin-left: 3%"
            v-if="customShows"
            v-model="form.callPhone"
            @input="$forceUpdate()"
            @change="engryCanCalls(form.callPhone)"
          ></el-input> -->
          <el-input
            style="width: 30%; margin-left: 3%"
            v-if="customShowTwos"
            v-model="form.callPhone"
            @input="$forceUpdate()"
            @change="engryCanCallsOne(form.callCustom)"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="自定义本机号">
          <el-select
            v-model="form.customTel"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in customNumbers"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="允许换卡">
          <el-select
            v-model="form.customTel"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in changeCard"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label-width="0px" style="text-align: center">
          <el-button type="primary" @click="onSubmit" style="padding: 14px 50px"
            >保 存</el-button
          >
        </el-form-item> -->
      </div>
      <!-- <div style="color: #d78673; font-size: 18px; margin-top: 3%">
      智能呼叫器:
    </div> -->
      <div
        style="
          padding: 0px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: start;
          margin-top: 2%;
        "
      >
        <el-form-item label="助理服务" style="width: 33.3%">
          <el-select
            v-model="form.careldServices"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in customerService"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="急救服务" style="width: 33.3%">
          <el-select
            v-model="form.ymServices"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in customerService"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="呼叫联系人" style="width: 33.3%">
          <el-select
            v-model="form.callContact"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
            disabled
          >
            <el-option
              v-for="item in customerService"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="健康顾问" style="width: 33.3%">
          <el-select
            v-model="form.healthAdvisor"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in customerService"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="法律咨询" style="width: 33.3%">
          <el-select
            v-model="form.legalAdvice"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in customerService"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关爱慰问" style="width: 33.3%">
          <el-select
            v-model="form.caringCondolen"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in customerService"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员服务包" style="width: 33.3%">
          <el-select
            v-model="form.basicServices"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in customerService"
              :key="item.code"
              :value="item.code"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>
    <div style="text-align: center; margin-bottom: 20px; margin-top: 10px">
      <el-button type="primary" @click="onSubmit" style="padding: 14px 50px"
        >保 存</el-button
      >
    </div>

    <!-- <div
      style="
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 3%;
        justify-content: start;
      "
    >
      <div
        style="
          color: #d78673;
          font-size: 18px;
          text-align: center;
          margin-top: -3%;
        "
      >
        适老设备：
      </div>
      <el-form-item label="急救提醒" style="width: 33.3%">
        <el-select
          v-model="form.remindAid"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div
      style="
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
      "
    >
      <div
        style="
          color: #d78673;
          font-size: 18px;
          text-align: center;
          margin-top: -3%;
        "
      >
        医疗设备：
      </div>
      <el-form-item label="健康提醒" style="width: 33.3%">
        <el-select
          v-model="form.remindHealth"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div
      style="
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
      "
    >
      <div
        style="
          color: #d78673;
          font-size: 18px;
          text-align: center;
          margin-top: -3%;
        "
      >
        安防设备：
      </div>
      <el-form-item label="安防提醒" style="width: 33.3%">
        <el-select
          v-model="form.remindSecurity"
          @input="$forceUpdate()"
          @change="$forceUpdate()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerService"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="0px" style="text-align: center">
        <el-button
          type="primary"
          @click="onSubmit"
          style="padding: 14px 50px; margin-left: 300px"
          >保 存</el-button
        >
      </el-form-item>
    </div> -->
  </div>
</template>

<script>
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      secretKey: "",
      isDisabled: true,
      loading: false,
      cusSystem: true,
      serverUrl: "",
      serverUrlOne: "",
      form: {
        region: "",
        nickname: "",
        alarmInterval: "",
        gpsFrequency: "",
        uploadInterval: "",
        heartInterval: "",
        oxygenInterval: "",
        tempInterval: "",
        specialSwitch: "",
        distrubStart: "",
        distrubEnd: "",
        distrubType: "",
        sosType: "",
        sosPhone: null,
        callFunction: "",
        callPhone: null,
        // customTel: "",
        ymServices: null,
        sosPhones: null,
        callPhoneOne: null,
        callPhoneTwo: null,
        callCustom: null,
        customTel: "",
        careldServices: null,
        callContact: -1,
        healthAdvisor: null,
        legalAdvice: null,
        caringCondolen: null,
        basicServices: null,
        otherServer: "",
        otherServerUrl: "",
      },
      isShow: true,
      changeCard: [],
      customerService: [
        {
          code: 0,
          name: "无",
        },
        {
          code: -1,
          name: "永久",
        },
        {
          code: 1,
          name: "1个月",
        },
        {
          code: 2,
          name: "2个月",
        },
        {
          code: 3,
          name: "3个月",
        },
        {
          code: 4,
          name: "4个月",
        },
        {
          code: 5,
          name: "5个月",
        },
        {
          code: 6,
          name: "6个月",
        },
        {
          code: 7,
          name: "7个月",
        },
        {
          code: 8,
          name: "8个月",
        },
        {
          code: 9,
          name: "9个月",
        },
        {
          code: 10,
          name: "10个月",
        },
        {
          code: 11,
          name: "11个月",
        },
        {
          code: 12,
          name: "12个月",
        },
      ],
      regionName: [],
      callPolices: [
        {
          value: "1",
          label: "01小时",
        },
        {
          value: "3",
          label: "03小时",
        },
        {
          value: "6",
          label: "06小时",
        },
        {
          value: "12",
          label: "12小时",
        },
        {
          value: "24",
          label: "24小时",
        },
      ],
      GpsOne: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      dataUploads: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      heartRates: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      bloodOxygens: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      temperatures: [
        {
          value: "300",
          label: "05分钟",
        },
        {
          value: "600",
          label: "10分钟",
        },
        {
          value: "900",
          label: "15分钟",
        },
        {
          value: "1800",
          label: "30分钟",
        },
        {
          value: "3600",
          label: "60分钟",
        },
      ],
      switchs: [
        {
          value: "0",
          label: "关",
        },
        {
          value: "1",
          label: "开",
        },
      ],
      customNumbers: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],
      serviceOpens: [
        {
          value: "0",
          label: "关",
        },
        {
          value: "1",
          label: "开",
        },
      ],
      patternStatus: [
        {
          code: "0",
          name: "关",
        },
        {
          code: "1",
          name: "开",
        },
      ],
      SosOne: [],
      callOne: [],
      shide: false,
      customShow: false,
      customShows: false,
      customShowTwos: false,
      valtel: "",
      valtels: "",
      valtelTwo: "",
      engCSeven: "",
      engCSevenOne: "",
      engCSevenTwo: "",
      codeOk: null,
      changeNum: false,
      secretKeyVal: "",
    };
  },
  created() {
    this.selectName();
    // this.selectStatus();
    this.selectSos();
    this.selectCall();
    this.yes();
    console.log(this.form.sosPhones, "sosppppp2");
    console.log(this.form.callPhoneOne, "callPhoneOne2");
    console.log(this.form.callPhoneTwo, "form.callPhoneTwo2");
    console.log(this.customShow, "customShow2");
    console.log(this.customShowTwos, "customShowTwos2");
    console.log(this.isShow, "isShow2");
    let groupList = JSON.parse(localStorage.getItem("groupList"));
    console.log("shuchu222222:", groupList);
    this.form = groupList;
    if (this.form.sosType == 3) {
      this.customShow = true;
    } else {
      this.customShow = false;
    }
    if (this.form.callFunction == 4) {
      this.customShowTwos = true;
    } else {
      this.customShowTwos = false;
    }
    if (this.form.distrubType == 1) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
    if (this.form.otherServer == "1") {
      this.cusSystem = true;
      this.loading = true;
      this.isDisabled = false;
    } else {
      this.cusSystem = false;
      this.loading = false;
      this.isDisabled = true;
    }
    if (this.form.otherServerUrl == null) {
      this.serverUrl = "";
      this.serverUrlOne = "";
    } else {
      var aa = this.form.otherServerUrl.split(",");
      // console.log("输出逗号的值：", aa);
      this.serverUrl = aa[0];
      this.serverUrlOne = aa[1];
    }
  },
  watch: {
    secretKey(val, oldV) {
      console.log("改变了：", val, oldV);
      if (val == "") {
        this.secretKeyVal = "";
      }
      //监听登录的密码输入框，将密文显示为*

      // this.secretKeyVal = val;
      this.secretKey = this.secretKey.replace(/./g, "*");

      if (oldV.length > val.length) {
        //进行删除操作   this.form.password就是定义在data中用来存实际密码的变量

        this.secretKeyVal = this.secretKeyVal.substring(
          0,
          this.secretKeyVal.length - 1
        );
        console.log("输出最后输入的是啥11111：", this.secretKeyVal);
      } else {
        this.secretKeyVal += val.replace(/[*]/g, "");
        console.log("输出最后输入的是啥：", this.secretKeyVal);
      }
    },

    // deep: true,
  },
  methods: {
    inputTwo(bb) {
      if (/^[\d]+$/.test(bb) == false) {
        this.$message.error("端口请输入数字");
        this.serverUrlOne = "";
        return false;
      }
    },
    switchChange(aa) {
      console.log("opopop:", aa);
      if (this.secretKey == "" && aa == true) {
        this.cusSystem = false;
        this.$message.error("请输入秘钥！");
        return false;
      } else if (this.secretKey == "" && aa == false) {
        this.cusSystem = true;
        this.$message.error("请输入秘钥！");
        return false;
      } else {
        this.changeNum = true;
        if (aa == false) {
          this.loading = false;
          this.form.otherServer = "0";
          this.isDisabled = true;
        } else {
          this.loading = true;
          this.isDisabled = false;
          this.form.otherServer = "1";
        }
      }
    },
    selectName() {
      api.getSelectProduct({ dictCode: "calldevicename" }).then((res) => {
        //console.log('下拉2：',res.data);
        if (res.data.code == 200) {
          // console.log(res.data, "下拉结果");
          this.regionName = res.data.result;
        }
      });
    },
    // selectStatus() {
    //   api.getSelectProduct({ dictCode: "disturbtype" }).then((res) => {
    //     if (res.data.code == 200) {
    //       // console.log(res.data, "下拉结果");
    //       this.patternStatus = res.data.result;
    //     }
    //   });
    // },
    yes() {
      api.getSelectProduct({ dictCode: "isno" }).then((res) => {
        // console.log(res, "isno");
        if (res.data.code === 200) {
          this.changeCard = res.data.result;
          // this.customerService = res.data.result;
        }
      });
    },
    startCan(val) {
      this.shide = true;
      this.$forceUpdate();
      console.log(val, "开始时间");
    },
    endCan(val) {
      this.shide = true;
      this.$forceUpdate();
      console.log(val, "结束时间");
    },
    patternCan(val) {
      this.shide = true;
      this.$forceUpdate();
      console.log(val, "免打扰震动");
      if (val == "1") {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    selectSos() {
      api.getSelectProduct({ dictCode: "sosnumbertype" }).then((res) => {
        if (res.data.code == 200) {
          // console.log(res.data, "下拉结果");
          this.SosOne = res.data.result;
        }
      });
    },
    engryCanSos(val) {
      if (val === "3") {
        this.customShow = true;
        this.form.sosPhone = this.sosPhones;
      } else {
        this.customShow = false;
        this.form.sosPhone = "";
      }
      this.engCSix = val;
      this.$forceUpdate();
      console.log(val, "sos");
    },
    engryCanSosS(val) {
      this.engCSixOne = val;
      this.valtels = val;
      this.$forceUpdate();
      console.log(val, "自定义号码");
    },
    selectCall() {
      api.getSelectProduct({ dictCode: "callfunctiontype" }).then((res) => {
        if (res.data.code == 200) {
          console.log(res.data, "下拉结果");
          this.callOne = res.data.result;
        }
      });
    },
    engryCanCall(val) {
      // if (val === "3") {
      //   this.customShows = true;
      //   this.form.callPhone = this.callPhoneOne;
      // } else {
      //   this.customShows = false;
      //   this.form.callPhone = "";
      // }
      if (val === "4") {
        this.customShowTwos = true;
        // this.form.callCustom = this.callPhoneTwo;
        this.form.callPhone = this.callPhoneOne;
      } else {
        this.customShowTwos = false;
        // this.form.callCustom = "";
        this.form.callPhone = "";
      }
      this.engCSeven = val;
      this.$forceUpdate();
      console.log(val, "呼叫功能");
    },
    engryCanCalls(val) {
      this.engCSevenOne = val;
      this.valtel = val;
      this.$forceUpdate();
      console.log(val, "自定义号码2");
    },
    engryCanCallsOne(val) {
      this.$forceUpdate();
      console.log(val, "自定义号码3");
      this.engCSevenTwo = val;
      this.valtelTwo = val;
    },
    onSubmit() {
      if (this.form.otherServer == "1") {
        if (this.serverUrl == "" || this.serverUrlOne == "") {
          this.$message.error("请输入客户服务器地址！");
          return false;
        }
      }
      if (this.form.otherServer == "1" && this.changeNum == true) {
        this.$confirm("是否继续保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.form.otherServerUrl = this.serverUrl + "," + this.serverUrlOne;
            console.log("保存");
            console.log(this.shide, "查看是否出发了免打扰设置");
            this.form.type = 5;
            let obj = {
              id: this.form.id,
              otherServer: this.form.otherServer,
              otherServerUrl: this.form.otherServerUrl,
              key: this.secretKeyVal,
            };
            apiShout.updateGroupNum(obj).then((res) => {
              this.codeOk = res.data.code;
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.$emit("codeOk", this.codeOk);
                console.log(res, "查看修改的数据");
                this.secretKey = "";
                this.changeNum = false;
                if (this.shide == true) {
                  this.shide = false;
                }
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else if (this.form.otherServer == "0" && this.changeNum == true) {
        this.$confirm("是否继续保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.engCSix === "1" || this.engCSix === "2") {
              this.engCSixOne = "";
            } else if (this.engCSix === "3") {
              this.engCSixOne = this.sosPhones || this.valtels;
            }
            if (this.engCSeven === "1" || this.engCSeven === "2") {
              this.engCSevenOne = "";
              this.engCSevenTwo = "";
            } else if (this.engCSeven === "3") {
              this.engCSevenOne = this.callPhoneOne || this.valtel;
              this.engCSevenTwo = "";
            } else if (this.engCSix === "4") {
              this.engCSevenTwo = this.callPhoneTwo || this.valtelTwo;
              this.engCSevenOne = "";
            }
            this.form.otherServerUrl = this.serverUrl + "," + this.serverUrlOne;
            console.log("保存");
            console.log(this.shide, "查看是否出发了免打扰设置");
            (this.form.createDate = []),
              (this.form.createId = []),
              (this.form.updateDate = []),
              (this.form.updateId = []),
              (this.form.delFlag = null);
            if (this.shide == false) {
              (this.form.distrubStart = null),
                (this.form.distrubEnd = null),
                (this.form.distrubType = null);
            }
            this.form.type = 5;
            apiShout
              .updateGroupNum({
                id: this.form.id,
                otherServer: this.form.otherServer,
                otherServerUrl: this.form.otherServerUrl,
                key: this.secretKeyVal,
              })
              .then((res) => {
                this.codeOk = res.data.code;
                if (res.data.code === 200) {
                  // this.$message({
                  //   type: "success",
                  //   message: "修改成功!",
                  // });
                  // this.$emit("codeOk", this.codeOk);
                  console.log(res, "查看修改的数据");
                  this.secretKey = "";
                  let obj = {
                    id: this.form.id,
                    type: this.form.type,
                    nickname: this.form.nickname,
                    alarmInterval: this.form.alarmInterval,
                    distrubStart: this.form.distrubStart,
                    distrubEnd: this.form.distrubEnd,
                    distrubType: this.form.distrubType,
                    heartInterval: this.form.heartInterval,
                    tempInterval: this.form.tempInterval,
                    oxygenInterval: this.form.oxygenInterval,
                    uploadInterval: this.form.uploadInterval,
                    gpsFrequency: this.form.gpsFrequency,
                    basicServices: this.form.basicServices,
                    caringCondolen: this.form.caringCondolen,
                    legalAdvice: this.form.legalAdvice,
                    healthAdvisor: this.form.healthAdvisor,
                    callContact: this.form.callContact,
                    ymServices: this.form.ymServices,
                    careldServices: this.form.careldServices,
                    customTel: this.form.customTel,
                    callPhone: this.form.callPhone,
                    callFunction: this.form.callFunction,
                    sosPhone: this.form.sosPhone,
                    sosType: this.form.sosType,
                    removeState: this.form.removeState,
                    specialSwitch: this.form.specialSwitch,
                    otherServer: this.form.otherServer,
                    otherServerUrl: this.form.otherServerUrl,
                  };
                  console.log(obj, "查看传过去的字段");
                  apiShout.updateGroupConfs(obj).then((res) => {
                    this.codeOk = res.data.code;
                    if (res.data.code === 200) {
                      this.$message({
                        type: "success",
                        message: "修改成功!",
                      });
                      this.$emit("codeOk", this.codeOk);
                      console.log(res, "查看修改的数据");
                      this.changeNum = false;
                      //   this.$nextTick(() => {
                      //     this.$parent.$parent.$parent.dialogVisibles = false;
                      //     console.log(
                      //       this.$parent.$parent.$parent.dialogVisibles,
                      //       "查看是否关闭"
                      //     );
                      //   });
                      if (this.shide == true) {
                        this.shide = false;
                      }
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  });
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.$confirm("是否继续保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.engCSix === "1" || this.engCSix === "2") {
              this.engCSixOne = "";
            } else if (this.engCSix === "3") {
              this.engCSixOne = this.sosPhones || this.valtels;
            }
            if (this.engCSeven === "1" || this.engCSeven === "2") {
              this.engCSevenOne = "";
              this.engCSevenTwo = "";
            } else if (this.engCSeven === "3") {
              this.engCSevenOne = this.callPhoneOne || this.valtel;
              this.engCSevenTwo = "";
            } else if (this.engCSix === "4") {
              this.engCSevenTwo = this.callPhoneTwo || this.valtelTwo;
              this.engCSevenOne = "";
            }
            // this.form.otherServerUrl = this.serverUrl + "," + this.serverUrlOne;
            // console.log("保存");
            // console.log(this.shide, "查看是否出发了免打扰设置");
            (this.form.createDate = []),
              (this.form.createId = []),
              (this.form.updateDate = []),
              (this.form.updateId = []),
              (this.form.delFlag = null);
            if (this.shide == false) {
              (this.form.distrubStart = null),
                (this.form.distrubEnd = null),
                (this.form.distrubType = null);
            }
            this.form.type = 5;
            let obj = {
              id: this.form.id,
              type: this.form.type,
              nickname: this.form.nickname,
              alarmInterval: this.form.alarmInterval,
              distrubStart: this.form.distrubStart,
              distrubEnd: this.form.distrubEnd,
              distrubType: this.form.distrubType,
              heartInterval: this.form.heartInterval,
              tempInterval: this.form.tempInterval,
              oxygenInterval: this.form.oxygenInterval,
              uploadInterval: this.form.uploadInterval,
              gpsFrequency: this.form.gpsFrequency,
              basicServices: this.form.basicServices,
              caringCondolen: this.form.caringCondolen,
              legalAdvice: this.form.legalAdvice,
              healthAdvisor: this.form.healthAdvisor,
              callContact: this.form.callContact,
              ymServices: this.form.ymServices,
              careldServices: this.form.careldServices,
              customTel: this.form.customTel,
              callPhone: this.form.callPhone,
              callFunction: this.form.callFunction,
              sosPhone: this.form.sosPhone,
              sosType: this.form.sosType,
              removeState: this.form.removeState,
              specialSwitch: this.form.specialSwitch,
            };
            console.log(obj, "查看传过去的字段");
            apiShout.updateGroupConfs(obj).then((res) => {
              this.codeOk = res.data.code;
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.$emit("codeOk", this.codeOk);
                console.log(res, "查看修改的数据");
                this.changeNum = false;
                if (this.shide == true) {
                  this.shide = false;
                }
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.colon {
  margin: auto 15px;
}
/deep/ .el-loading-mask {
  opacity: 0.6;
  cursor: no-drop;
}
</style>